import Bgimage from "./bg-main.jpg";
import React, { useState } from "react";
import "./App.css";
import Secondimage from "./pos_logo.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Selectimg from "./du.png";
import Selectimg2 from "./eti_en.png";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function App() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const redirectToDu = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.set("country_code", "AE");
    urlParams.set("token", "0f0a7cae-9452-44a2-aeeb-e131c1a343b6");
    urlParams.set("publisher_id", "10");

    const qs = new URLSearchParams(urlParams).toString();
    const url = `/clk3p?${qs}`;
    window.location.href = url;
  };
  const redirectToEti = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.set("country_code", "AE");
    urlParams.set("token", "0f0a7cae-9452-44a2-aeeb-e131c1a343b6");
    urlParams.set("publisher_id", "10");
    const qs = new URLSearchParams(urlParams).toString();
    const url = `/clk?${qs}`;
    window.location.href = url;
  };
  return (
    <div className="App">
      <img src={Bgimage} className="App-logo" alt="logo" />
      <div className="sec">
        <img src={Secondimage} alt="logo" className="second" />
      </div>
      <div className="center">
        <h2 className="line">
          {" "}
          7 Emirates <span className="spa">/</span> 12 New Winners
          <br />
          AED 90,000 to be won!
        </h2>
        <h3 className="line-sub1">
          New Prizes! AED 90,000 Cash in total!
          <br />
          Join now, answer questions and the next Cash Prize can be yours!
        </h3>
        <h3 className="line-sub2">
          Next Draw in a few days!
          <br />
          All du and Etisalat subscribers can participate!
        </h3>
        <button className="btn" onClick={handleOpen}>
          Subscribe
        </button>
        <h3 className="line-sub2">
          All DU and Etisalat subscribers can participate! After 1st free day,
          only AED 3.15 (VAT inclusive)/day applies
        </h3>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="div_close" onClick={handleClose}>
              X
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text"
            >
              Select your provider:
            </Typography>
            <div className="modal_div">
              <div className="img_div" onClick={redirectToDu}>
                <img src={Selectimg} alt="img" className="img_" />
              </div>
              <div onClick={redirectToEti}>
                <img src={Selectimg2} alt="img" className="img_" />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <footer>
        <div>
          <h2 className="line-sub1">
            TNC <span>|</span> FAQ
          </h2>
        </div>
      </footer>
    </div>
  );
}

export default App;
